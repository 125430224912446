

















import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class CancelValidation extends Vue {
  tabs = [
    {
      label: 'List Cancel',
      name: 'CancelValidationTabCancelList',
    },
    {
      label: 'List Kurir Cancel',
      name: 'CancelValidationTabCourierCancelList',
    },
  ]
}
